import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Components
import StandardButton from "../../../components/Buttons/StandardButton";
import JobCard from "../../../components/Cards/JobCard";
import ReorderCardWrapper from "../../../components/Cards/ReorderCardWrapper";
import HeaderBar from "../../../components/Header/HeaderBar";
import LabeledTextboxInput from "../../../components/Inputs/LabeledTextboxInput";
import StandardLabel from "../../../components/Labels/StandardLabel";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";

// Services
import axiosJobs from "../../../services/axios/jobs";
import axiosRoutes from "../../../services/axios/routes";
// DEPRECATED: TODO: can this be removed?  It's no longer used by the routes page
const AdminCreateRoutePage = (props) => {
  const navigate = useNavigate();

  // Get season from url
  let { season } = useParams();

  const [createButtonLoading, setCreateButtonLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [routeDescription, setRouteDescription] = useState("");
  const [routeName, setRouteName] = useState("");
  const [showAddPropertyWindow, setShowAddPropertyWindow] = useState(false);

  const handleCreateRoute = () => {
    setCreateButtonLoading(true);

    // Abstract jobIds
    let jobIds = jobs.map((j) => j._id);

    axiosRoutes.createNewRoute(
      {
        companyId: props.currentUser.currentCompanyId,
        details: routeDescription,
        name: routeName,
        jobIds,
        season,
      },
      () => {
        setCreateButtonLoading(false);
        // Go Back
        navigate(-1);
      },
    );
  };

  const handleReorder = (from, to) => {
    // Return if on either end of array
    if (from === -1 || to === -1) return;
    if (from === jobs.length || to === jobs.length) return;

    const updatedJobs = [...jobs];
    updatedJobs.splice(to, 0, updatedJobs.splice(from, 1)[0]);
    setJobs(updatedJobs);
  };

  return (
    <div className="flex flex-col items-center flex-1 min-h-full">
      <HeaderBar
        title="Create Route"
        buttons={[
          {
            color: "green",
            disabled: !routeName || jobs.length === 0,
            label: "Create",
            onClick: handleCreateRoute,
            loading: createButtonLoading,
          },
        ]}
      />
      <div className="flex w-full flex-1">
        <div className="flex flex-1 flex-col w-full p-10 items-center overflow-y-scroll">
          <LabeledTextboxInput
            label="Name"
            placeholder="Name your route..."
            setValue={(val) => setRouteName(val)}
            value={routeName}
          />
          <LabeledTextboxInput
            label="Description"
            placeholder="Tell us more about this route..."
            setValue={(val) => setRouteDescription(val)}
            value={routeDescription}
          />

          <div className="w-full mt-4">
            <div className="flex flex-col w-full items-end mb-2.5">
              <StandardButton
                color={showAddPropertyWindow ? "red" : "blue"}
                label={
                  showAddPropertyWindow
                    ? "Close Property Window"
                    : "Add Properties"
                }
                onClick={() => setShowAddPropertyWindow(!showAddPropertyWindow)}
              />
            </div>
            {jobs.length > 0 ? <StandardLabel label="Properties" /> : null}
            {jobs.map((job, key) => (
              <ReorderCardWrapper
                key={key}
                downPressed={() => handleReorder(key, key + 1)}
                upPressed={() => handleReorder(key, key - 1)}
              >
                <JobCard
                  address={job.address}
                  name={job.name}
                  onCardPress={null}
                  onRightIconClicked={() => {
                    const updatedJobs = [...jobs];
                    updatedJobs.splice(key, 1);
                    setJobs([...updatedJobs]);
                  }}
                  showDelete={true}
                />
              </ReorderCardWrapper>
            ))}
          </div>
        </div>
        {showAddPropertyWindow ? (
          <AddPropertyWrapper
            active={showAddPropertyWindow}
            companyId={props.currentUser.currentCompanyId}
            season={season}
            setShowProperty={setShowAddPropertyWindow}
            concatJobs={(newJobs) => {
              setJobs([...jobs, ...newJobs]);
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

const AddPropertyWrapper = (props) => {
  const [jobsLoading, setJobsLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [selectedJobIds, setSelectedJobIds] = useState([]);

  useEffect(() => {
    if (!props.active) return;
    setJobsLoading(true);
    axiosJobs.getAllJobsForSeason(
      { companyId: props.companyId, season: props.season },
      (data) => {
        setJobs(data);
        setJobsLoading(false);
      },
    );
  }, [props.active]);

  const handleAddJobs = () => {
    const jobsToAdd = selectedJobIds.map((j) =>
      jobs.find((element) => element._id === j),
    );

    props.concatJobs(jobsToAdd);
    props.setShowProperty(false);
  };

  const handleCardPressed = (index) => {
    let updatedJobs = [...jobs];
    const updatedJob = { ...updatedJobs[index] };
    let updatedSelectedJobIds = [...selectedJobIds];

    // If job not already selected, select
    if (!updatedJob.cardSequence) {
      updatedJobs[index].cardSequence = selectedJobIds.length + 1;
      updatedSelectedJobIds.push(updatedJob._id);
    } else {
      updatedSelectedJobIds = updatedSelectedJobIds.filter(
        (id) => id !== updatedJob._id,
      );
      updatedJobs = updatedJobs.map((job, i) => {
        if (index === i) {
          // selected job
          return { ...job, cardSequence: null };
        } else if (job.cardSequence > updatedJob.cardSequence) {
          // greater than selected job
          return { ...job, cardSequence: job.cardSequence - 1 };
        } else {
          // less than selected job
          return { ...job };
        }
      });
    }

    // Update State
    setJobs(updatedJobs);
    setSelectedJobIds(updatedSelectedJobIds);
  };

  return (
    <div className="flex flex-1 flex-col w-full p-10 items-center bg-snow-blue-3 overflow-y-scroll">
      <div className="flex flex-row w-full justify-between">
        <div className="text-2xl font-bold text-snow-white">
          Select jobs to add
        </div>
        <StandardButton color="green" label="Add" onClick={handleAddJobs} />
      </div>

      {jobsLoading ? (
        <LoadingIcon color="white" />
      ) : (
        <div className="w-full my-3">
          {jobs.map((job, key) => (
            <JobCard
              address={job.address}
              cardSequence={job.cardSequence}
              key={key}
              onClick={() => {
                handleCardPressed(key);
              }}
              name={job.name}
            />
          ))}
          {jobs.length === 0 ? (
            <div className="text-lg text-snow-white">
              {`Notice: There are no properties configured for this
                            season yet. Go to the Properties page and create a
                            new one or update an existing property and enable
                            the ${props.season.toUpperCase()} season.`}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(AdminCreateRoutePage);
