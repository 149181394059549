import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// Components
// import FilterBar from '../../../components/Filters/FilterBar';
import StandardButton from "../../../components/Buttons/StandardButton";
import SubtleButton from "../../../components/Buttons/SubtleButton";
import JobCard from "../../../components/Cards/JobCard";
import ReorderCardWrapper from "../../../components/Cards/ReorderCardWrapper";
import StartARouteCard from "../../../components/Cards/StartARouteCard";
import HeaderBar from "../../../components/Header/HeaderBar";
import LabeledTextboxInput from "../../../components/Inputs/LabeledTextboxInput";
import StandardLabel from "../../../components/Labels/StandardLabel";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";
import MultipleChoiceModal from "../../../components/Modals/MultipleChoiceModal";
import NotificationModal from "../../../components/Modals/NotificationModal";

// Services
import axiosJobs from "../../../services/axios/jobs";
import axiosRoutes from "../../../services/axios/routes";
import TwistedArrow from "../../../resources/twisted-arrow.svg";
import axios from "axios";

const UpdateRoutePage = (props) => {
  const navigate = useNavigate();
  let { routeId } = useParams();
  const isNew = routeId === "new";
  let [searchParams] = useSearchParams();

  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [displayNotificationModal, setDisplayNotificationModal] =
    useState(false);
  const [displayDeactivateModal, setDisplayDeactivateModal] = useState(false);
  const [headerLabel, setHeaderLabel] = useState("Route");
  const [loading, setLoading] = useState(!isNew);
  const [jobs, setJobs] = useState([]);
  const [route, setRoute] = useState({
    season: isNew ? searchParams.get("season") : undefined,
  });
  const [showAddPropertyWindow, setShowAddPropertyWindow] = useState(false);

  useEffect(() => {
    if (!isNew) {
      setLoading(true);
      axios
        .get(`route/${routeId}?include=jobs`)
        .then((resp) => {
          setRoute(resp.data);
          setHeaderLabel(resp.data.name);
          setJobs(resp.data.jobs);
        })
        .finally(() => setLoading(false));
    }
  }, [isNew, routeId]);

  const handleSaveRoute = () => {
    setLoading(true);

    // Abstract jobIds
    let jobIds = jobs.map((j) => j._id);

    if (isNew) {
      axiosRoutes.createNewRoute(
        {
          companyId: props.currentUser.currentCompanyId,
          details: route.details,
          name: route.name,
          jobIds,
          season: route.season,
        },
        () => {
          setLoading(false);
          navigate(-1); // Go Back
        },
      );
    } else {
      axiosRoutes.updateRoute(
        {
          companyId: props.currentUser.currentCompanyId,
          details: route.details,
          name: route.name,
          jobIds,
          _id: routeId,
        },
        () => {
          setLoading(false);
          // Go Back
          navigate(-1);
        },
      );
    }
  };

  const handleDeleteRoute = () => {
    axiosRoutes.deleteRoute(
      { routeId: route._id },
      () => {
        setDisplayNotificationModal(true);
      },
      (err) => {
        alert(
          "Oops. Something went wrong while deleting this route. Please try again.",
        );
        console.log("Delete Route Error: ", err);
      },
    );
    return;
  };

  const handleUpdateRouteIsActive = () => {
    axiosRoutes.updateRoute(
      {
        _id: route._id,
        isActive: !route.isActive,
      },
      () => {
        setRoute({ ...route, isActive: !route.isActive });
        setLoading(false);
        setDisplayDeactivateModal(false);
      },
      (err) => {
        alert(
          "Oops. Something went wrong while deactivating this route. Please try again.",
        );
        console.log("Deactivate Route Error: ", err);
        setLoading(false);
      },
    );
  };

  const handleReorder = (from, to) => {
    // Return if on either end of array
    if (from === -1 || to === -1) return;
    if (from === jobs.length || to === jobs.length) return;

    const updatedJobs = [...jobs];
    updatedJobs.splice(to, 0, updatedJobs.splice(from, 1)[0]);
    setJobs(updatedJobs);
  };

  let buttons = [];
  if (!isNew) {
    buttons.push({
      label: "Delete",
      color: "red",
      onClick: () => setDisplayDeleteModal(true),
    });
    buttons.push({
      label: route.isActive ? "Make Inactive" : "Activate",
      color: "blue",
      onClick: () => setDisplayDeactivateModal(true),
    });
  }
  buttons.push({
    label: "Save",
    color: "green",
    onClick: handleSaveRoute,
    disabled: !route.name || jobs.length < 1,
  });

  return (
    <>
      <div className="flex flex-col items-center flex-1 min-h-full">
        <HeaderBar title={headerLabel} buttons={buttons} />
        {loading ? (
          <LoadingIcon />
        ) : (
          <div className="flex w-full flex-1">
            <div
              className={`flex-1 flex-col w-full p-10 items-center overflow-y-scroll ${showAddPropertyWindow ? "hidden lg:flex" : "flex"}`}
            >
              <LabeledTextboxInput
                label="Name"
                placeholder="Name your route..."
                setValue={(val) => {
                  setRoute({ ...route, name: val });
                }}
                value={route.name}
              />
              <LabeledTextboxInput
                label="Description"
                placeholder="Tell us more about this route..."
                setValue={(val) => {
                  setRoute({ ...route, details: val });
                }}
                value={route.details}
              />

              <div className="w-full mt-4">
                <div className="flex flex-col w-full items-end mb-2.5">
                  <SubtleButton
                    onClick={() => setShowAddPropertyWindow(true)}
                    testId="add-properties-button"
                    disabled={showAddPropertyWindow}
                  >
                    + Add Properties
                  </SubtleButton>
                </div>
                {isNew ? null : (
                  <div
                    className="flex flex-row justify-end align-top w-full mb-2.5 pr-3"
                    data-testid="order-properties-text"
                  >
                    <span className="mr-2 font-hand font-bold text-lg">
                      Order your properties here
                    </span>
                    <img
                      alt="-->"
                      className="w-10 relative top-4"
                      src={TwistedArrow}
                    />
                  </div>
                )}
                {jobs.length > 0 ? <StandardLabel label="Properties" /> : null}
                <div data-testid="existing-properties">
                  {jobs.map((job, key) => (
                    <ReorderCardWrapper
                      key={key}
                      downPressed={() => handleReorder(key, key + 1)}
                      upPressed={() => handleReorder(key, key - 1)}
                    >
                      <JobCard
                        address={job.address}
                        name={job.name}
                        onCardPress={null}
                        onRightIconClicked={() => {
                          const updatedJobs = [...jobs];
                          updatedJobs.splice(key, 1);
                          setJobs([...updatedJobs]);
                        }}
                        showDelete={true}
                      />
                    </ReorderCardWrapper>
                  ))}
                </div>
              </div>
              <div className="flex items-start w-full mt-10">
                <StartARouteCard></StartARouteCard>
              </div>
            </div>
            {showAddPropertyWindow ? (
              <AddPropertyWrapper
                onCancel={() => setShowAddPropertyWindow(false)}
                active={showAddPropertyWindow}
                companyId={props.currentUser.currentCompanyId}
                season={route.season}
                setShowProperty={setShowAddPropertyWindow}
                existingJobs={jobs}
                concatJobs={(newJobs) => {
                  setJobs([...jobs, ...newJobs]);
                }}
              />
            ) : null}
          </div>
        )}
      </div>
      <MultipleChoiceModal
        open={displayDeleteModal}
        options={[
          {
            color: "red",
            label: "Delete Permanently",
            onClick: () => {
              setDisplayDeleteModal(false);
              handleDeleteRoute();
            },
          },
          {
            color: "blue",
            label: "Cancel",
            onClick: () => setDisplayDeleteModal(false),
          },
        ]}
        subtitle="Are you sure you want to delete this route? Warning, this is a permanent action."
        title="Delete"
      />
      <MultipleChoiceModal
        open={displayDeactivateModal}
        options={[
          {
            color: "green",
            label: route.isActive ? "Make Inactive" : "Activate Route",
            onClick: () => {
              setDisplayDeactivateModal(false);
              handleUpdateRouteIsActive();
            },
          },
          {
            color: "blue",
            label: "Cancel",
            onClick: () => setDisplayDeactivateModal(false),
          },
        ]}
        subtitle={
          route.isActive
            ? "If you deactivate this route employees will no longer be able to perform this route until you re-activate it. All data will be kept and you can re-activate this route at any time."
            : "Are you sure you want to activate this route? You will be able to see this route at the top of the list of routes while using the admin web portal."
        }
        title={route.isActive ? "Make Inactive" : "Activate Route"}
      />

      <NotificationModal
        button={{
          color: "blue",
          label: "Okay",
          onClick: () => {
            // Close and go back
            setDisplayNotificationModal(false);
            navigate(-1);
          },
        }}
        open={displayNotificationModal}
        subtitle="Congratulations! This route has been deleted."
        title="Deleted"
      />
    </>
  );
};

const AddPropertyWrapper = (props) => {
  // const [currentFilter, setCurrentFilter] = useState('all');
  const [jobsLoading, setJobsLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [selectedJobIds, setSelectedJobIds] = useState([]);

  useEffect(() => {
    if (!props.active) return;
    setJobsLoading(true);
    axiosJobs.getAllJobsForSeason(
      { companyId: props.companyId, season: props.season },
      (data) => {
        setJobs(data);
        setJobsLoading(false);
      },
    );
  }, [props.active]);

  const handleAddJobs = () => {
    const jobsToAdd = selectedJobIds.map((j) =>
      jobs.find((element) => element._id === j),
    );

    props.concatJobs(jobsToAdd);
    props.setShowProperty(false);
  };

  const handleCardPressed = (index) => {
    let updatedJobs = [...jobs];
    const updatedJob = { ...updatedJobs[index] };
    let updatedSelectedJobIds = [...selectedJobIds];

    // If job not already selected, select
    if (!updatedJob.cardSequence) {
      updatedJobs[index].cardSequence = selectedJobIds.length + 1;
      updatedSelectedJobIds.push(updatedJob._id);
    } else {
      updatedSelectedJobIds = updatedSelectedJobIds.filter(
        (id) => id !== updatedJob._id,
      );
      updatedJobs = updatedJobs.map((job, i) => {
        if (index === i) {
          // selected job
          return { ...job, cardSequence: null };
        } else if (job.cardSequence > updatedJob.cardSequence) {
          // greater than selected job
          return { ...job, cardSequence: job.cardSequence - 1 };
        } else {
          // less than selected job
          return { ...job };
        }
      });
    }

    // Update State
    setJobs(updatedJobs);
    setSelectedJobIds(updatedSelectedJobIds);
  };

  return (
    <div
      className="flex flex-1 flex-col w-full p-4 lg:p-10 items-center bg-snow-blue-3 overflow-y-scroll"
      data-testid="add-jobs-dialog"
    >
      {/* <FilterBar
                currentFilter={currentFilter}
                filters={[
                    { label: 'All', value: 'all' },
                    { label: 'Landscape', value: 'landscape' },
                    { label: 'Snow', value: 'snow' },
                ]}
                lightMode={true}
                setCurrentFilter={setCurrentFilter}
            /> */}
      <div className="flex flex-col lg:flex-row w-full justify-between">
        <div className="text-2xl font-bold text-snow-white order-2 lg:order-1">
          Select properties to add
        </div>
        <div className="flex gap-4 justify-end order-1 lg:order-2">
          <StandardButton
            color="red"
            label="Cancel"
            onClick={() => (props.onCancel ? props.onCancel() : null)}
            testId="cancel-add-button"
          />
          <StandardButton
            color="green"
            label="Add"
            onClick={handleAddJobs}
            testId="complete-add-properties-button"
          />
        </div>
      </div>
      <p className="text-full-white text-sm my-2">
        Properties that are already part of this route are grayed out, but can
        still be added if you want to service a property more than once during a
        route.
      </p>

      {jobsLoading ? (
        <LoadingIcon color="white" />
      ) : (
        <div className="w-full my-3">
          {jobs.map((job, key) => (
            <JobCard
              address={job.address}
              cardSequence={job.cardSequence}
              key={key}
              onClick={() => {
                handleCardPressed(key);
              }}
              name={job.name}
              className={`${props.existingJobs.find((ej) => ej._id == job._id) !== undefined ? "opacity-70" : ""} cursor-pointer`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state;
  return { currentUser };
};

export default connect(mapStateToProps)(UpdateRoutePage);
export { UpdateRoutePage };
