import axios from "axios";
import errors from "../errors";

const noop = () => {};

const axiosRoutes = {
  orderRoute: async (routeId, order) => {
    return axios.put(`route/${routeId}/order`, { order });
  },
  createNewRoute: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post("route/add", data);

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  deleteRoute: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.delete("route/" + data.routeId + "/delete", data);

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  getAllRoutes: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.get(
        "route/company/" + data.companyId + "?take=10000",
      );

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  getAllRoutesAsync: async (companyId, abortSignal) => {
    try {
      const res = await axios.get(
        "route/company/" + companyId + "?take=10000",
        {
          signal: abortSignal,
        },
      );
      return res.data;
    } catch (error) {
      errors.report(error);
      throw error;
    }
  },
  // Get jobs list (include duplicate) for single route by routeId
  getJobListByRouteId: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.get("route/" + data.routeId + "/jobs/all");
      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  getRouteById: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.get("route/" + data.routeId);
      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
  updateRoute: async (data, onSuccess = noop, onFail = noop) => {
    try {
      const res = await axios.post("route/update", data);

      onSuccess(res.data);
    } catch (error) {
      errors.report(error);
      onFail(error);
    }
  },
};

export default axiosRoutes;
