import React from "react";
import LoadingIcon from "./Loaders/LoadingIcon";
export default function ContentWrapper({
  children,
  className,
  loading = false,
}) {
  return (
    <div className={`p-4 md:p-10 w-full ${className ?? ""}`}>
      {loading ? (
        <div className=" flex justify-center">
          <LoadingIcon />
        </div>
      ) : (
        children
      )}
    </div>
  );
}
