import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import AddressWidget from "../../../components/Address/AddressWidget";
import StandardButton from "../../../components/Buttons/StandardButton";
import HeaderBar from "../../../components/Header/HeaderBar";
import ImageNoteScroller from "../../../components/Images/ImageNoteScroller";
import ImageWithNotesScroller from "../../../components/Images/ImageWithNotesScroller";
import LabeledTextboxInput from "../../../components/Inputs/LabeledTextboxInput";
import LoadingIcon from "../../../components/Loaders/LoadingIcon";
import ServicesField from "../../../components/Services/ServicesField";
import MaterialsConsumedTable from "../../../components/Tables/MaterialsConsumedTable";
import CrossCircleRed from "../../../resources/cross-circle-red.svg";
import backend from "../../../services/axios/backendAxios";
import axiosJobHistory from "../../../services/axios/jobHistory";
import flash from "../../../services/flash";
import axios from "axios";
import errors from "../../../services/errors";
import TwistedArrow from "../../../resources/twisted-arrow.svg";
import Until from "../../../components/Until";
import { format, formatDistanceStrict } from "date-fns";
import LabeledField from "../../../components/Labels/LabeledField";
const AdminJobHistoryDetailsPage = () => {
  let { jobHistoryId } = useParams();

  const [displayOptionsPopup, setDisplayOptionsPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [jobHistory, setJobHistory] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosJobHistory.getJobHistoryById({ _id: jobHistoryId }, (jobHistory) => {
      setJobHistory(jobHistory);
      // setHeaderLabel(jobHistory.name);
      setLoading(false);
    });
  }, [jobHistoryId]);

  const handleResendReportEmail = () => {
    if (!jobHistoryId) return;

    axiosJobHistory.resendReportEmail(
      { jobHistoryId },
      () => {
        alert("Successfully Sent");
      },
      (err) => {
        alert(err);
      },
    );
  };

  const updateMaterials = (key, val) => {
    let b = { ...jobHistory };
    // check if valid number
    if (!isNaN(Number(val))) {
      b.materialsConsumed[key].quantity = Number(val);
      setJobHistory(b);
      backend
        .patch(`/material-consumed/${b.materialsConsumed[key]._id}`, {
          quantity: val,
        })
        .then(() => {
          flash.success("Saved: Material consumption updated");
        });
    }
  };

  const updateJobHistory = useCallback(async (jobHistory) => {
    try {
      let data = (
        await axios.post(`job/history/add?return_with_materials=1`, jobHistory)
      ).data;
      setJobHistory(data.jobHistory);
      flash.success("Property history updated.");
      setIsDirty(false);
    } catch (err) {
      errors.report(err);
      flash.error(
        "Unable to save your changes.  Please refresh and try again.",
      );
    } finally {
      setSaving(false);
    }
  });

  const handleClearFlaggedForReviewClick = () => {
    updateJobHistory({
      ...jobHistory,
      flaggedForReview: false,
    });
  };

  const modifyJH = useCallback((newJobHistoryData) => {
    setJobHistory(newJobHistoryData);
    setIsDirty(true);
  });

  let buttons = [
    {
      color: "blue",
      label: "Resend Report",
      disabled: isEditing,
      onClick: () => handleResendReportEmail(),
    },
  ];
  if (isEditing) {
    buttons.push({
      color: "green",
      // disabled: !isDirty,
      label: "Save Changes",
      loading: saving,
      onClick: () => {
        updateJobHistory(jobHistory);
        setIsEditing(false);
      },
    });
  } else {
    buttons.push({
      color: "blue",
      label: "Edit Record",
      onClick: () => setIsEditing(true),
    });
  }
  let until = new Date("2024-10-01T00:00:00.000Z");

  return (
    <div className="flex flex-col items-center">
      <HeaderBar title="Property Service Record" buttons={buttons} />
      <Until until={until}>
        <div className="flex flex-row justify-end align-top mb-2.5 pr-20 w-full print:hidden">
          <span className="mr-2 font-hand font-bold text-lg">
            Now you can edit this property history
          </span>
          <img
            alt="-->"
            className="w-10 relative -top-4"
            src={TwistedArrow}
            style={{ transform: "scaleY(-1)" }}
          />
        </div>
      </Until>
      {loading ? (
        <LoadingIcon />
      ) : (
        <div
          className={`flex flex-1 flex-col w-full p-10 items-center overflow-y-scroll mb-8 ${new Date() < until ? "-mt-10" : ""}`}
        >
          {jobHistory.flaggedForReview && (
            <div className="flex items-center justify-between px-8 bg-snow-red w-full mb-8 py-4 rounded-lg">
              <span className="text-xl text-full-white">
                Flagged for Review
              </span>
              <div
                className="text-full-white cursor-pointer"
                onClick={handleClearFlaggedForReviewClick}
              >
                <img
                  className="w-12"
                  src={CrossCircleRed}
                  alt="Unflag for review"
                />
              </div>
            </div>
          )}
          <div className="flex flex-col w-full justify-between sm:flex-row">
            <div className="text-3xl font-medium text-snow-primary">
              {jobHistory.name}
            </div>
            <AddressWidget address={jobHistory.address} />
          </div>
          <p className="text-sm text-snow-primary w-full mb-4">
            Serviced by {jobHistory.completedBy.firstName}{" "}
            {jobHistory.completedBy.lastName} (
            {jobHistory.completedBy.phoneNumber})<br />
            At {formatTime(jobHistory.startTimeUTC)} until{" "}
            {formatTime(jobHistory.endTimeUTC)}{" "}
            {jobHistory.endTimeUTC
              ? "(" +
                formatDistanceStrict(
                  new Date(jobHistory.endTimeUTC),
                  new Date(jobHistory.startTimeUTC),
                ) +
                ")"
              : ""}
          </p>

          <ImageNoteScroller
            enableUpload={false}
            imageArray={jobHistory.sitemaps}
            isSitemap={true}
            label="Sitemaps"
          />
          <LabeledField label="Instructions">
            <p>{jobHistory.instructions}</p>
          </LabeledField>
          {isEditing ? (
            <LabeledTextboxInput
              disabled={false}
              label="Operator Notes"
              multiline={true}
              value={jobHistory.notes}
              setValue={(value) => {
                modifyJH({ ...jobHistory, notes: value });
              }}
            />
          ) : (
            <LabeledField label="Operator Notes">
              <p>{jobHistory.notes}</p>
            </LabeledField>
          )}
          <ServicesField
            key="service-fields"
            label="Services Completed"
            subtitle="The following services were completed during this visit to the property."
            services={jobHistory.servicesPerformed}
            setServices={(newServices) => {
              modifyJH({ ...jobHistory, servicesPerformed: newServices });
            }}
            showSubOptions={true}
            readOnly={!isEditing}
            hideUnchecked={!isEditing}
          />
          <MaterialsConsumedTable
            materialsConsumed={jobHistory.materialsConsumed}
            onMaterialUpdate={updateMaterials}
            readOnly={!isEditing}
          ></MaterialsConsumedTable>
          <LabeledField label="Property Site Images">
            <ImageWithNotesScroller
              enableUpload={isEditing}
              readOnly={!isEditing}
              imageArray={jobHistory.completedJobImages}
              label="Property Site Images"
              enableDelete={isEditing}
              setImageArray={(newArray) => {
                modifyJH({ ...jobHistory, completedJobImages: newArray });
              }}
            />
          </LabeledField>
          {jobHistory.updatedBy ? (
            <div className="py-4">
              <span style={{ marginRight: 7 }}>&#9432;</span>Notice: This record
              was updated at{" "}
              {format(new Date(jobHistory.updatedAt), "yyyy-MM-dd HH:mm")} by{" "}
              {jobHistory.updatedBy?.firstName} {jobHistory.updatedBy?.lastName}
              .
            </div>
          ) : null}
          <div className="text-snow-light-grey text-xs">
            Record ID: {jobHistory._id}
          </div>
        </div>
      )}
    </div>
  );
};
function formatTime(dateString) {
  try {
    if (dateString) {
      let date = new Date(dateString);
      return format(date, "MMM d, yyyy h:mm bbb");
    } else {
      return "In Progress";
    }
  } catch (err) {
    errors.report(err, { message: "Unable to format time on a job history" });
  }
}
export default AdminJobHistoryDetailsPage;
